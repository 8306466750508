import './TemplateSteps.css';
import '../../Root.css';

export default function NavbarSteps() {
    return (
        <>
           <nav>
                <a href='/home'><img src='https://firebasestorage.googleapis.com/v0/b/third--space.appspot.com/o/ImageWebSite%2F3s-logo.png?alt=media&token=8a69bcce-2e9f-463e-8cba-f4c2fec1a904'/></a>
           </nav>
        </>
    )
}